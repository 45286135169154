<template>
  <div>
    <div class="content2">
      <vs-alert
        v-if="msg_error != ''"
        :active="msg_error"
        :color="colorMsgPassword"
        icon="new_releases"
      >
        <span>{{ this.msg_error }}</span>
      </vs-alert>
      <div class="flex">
        <div class="input-pass" style="width: 77.734%; margin: 1.5rem 0px">
          <label class="font-bold"> {{ $t("oldPassword") }} </label>
          <svg
            @click="showHidePass('oldPassword')"
            xmlns="http://www.w3.org/2000/svg"
            width="22.172"
            height="19"
            viewBox="0 0 22.172 19"
          >
            <path
              id="Icon_ionic-md-eye-off"
              data-name="Icon ionic-md-eye-off"
              d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
              transform="translate(-2.25 -4.5)"
              fill="#848484"
            />
          </svg>
          <input
            ref="oldPassword"
            type="password"
            class="input"
            v-model="oldPassword"
          />
        </div>
      </div>
      <div class="flex">
        <div class="input-pass">
          <label class="font-bold"> {{ $t("newPassword") }} </label>
          <svg
            @click="showHidePass('newPassword')"
            xmlns="http://www.w3.org/2000/svg"
            width="22.172"
            height="19"
            viewBox="0 0 22.172 19"
          >
            <path
              id="Icon_ionic-md-eye-off"
              data-name="Icon ionic-md-eye-off"
              d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
              transform="translate(-2.25 -4.5)"
              fill="#848484"
            />
          </svg>
          <input
            ref="newPassword"
            type="password"
            v-model="password"
            class="input"
            name="password"
            v-validate="'required|min:8'"
          />
        </div>
        <div class="input-pass">
          <label class="font-bold"> {{ $t("returnNewPassword") }} </label>
          <svg
            @click="showHidePass('password')"
            xmlns="http://www.w3.org/2000/svg"
            width="22.172"
            height="19"
            viewBox="0 0 22.172 19"
          >
            <path
              id="Icon_ionic-md-eye-off"
              data-name="Icon ionic-md-eye-off"
              d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
              transform="translate(-2.25 -4.5)"
              fill="#848484"
            />
          </svg>
          <input
            @input="checkPassword('password')"
            ref="password"
            type="password"
            v-model="password2"
            class="input"
            v-validate="'required|min:8'"
          />
          <span v-if="!check" class="text-danger text-sm ml-2"
            >يجب ان تكون متطابقة لكلمة السر الاولي</span
          >
        </div>
      </div>
      <span class="text-danger text-sm">{{ errors.first("password") }}</span>
    </div>

    <!--  -->
    <div class="input-pass flex gap-2 mt-6">
      <input
        type="checkbox"
        name="logout_devices"
        id="logout_devices"
        class="input"
        @change="(e) => logoutFromAllDevices = e.target.checked"
      />
      <label for="logout_devices" class="font-bold text-sm">
        {{ 'تسجيل الخروج من جميع الأجهزة؟' }}
      </label>
    </div>

    <div class="btn">
      <button
        @click="submit"
        :class="{ 'opacity-btn1': !isFormValid }"
        :disabled="!isFormValid || loading"
        class="btn-save"
      >
        {{ loading ? "جاري التعديل" : $t("saveModification") }}
      </button>
      <router-link to="/"
        ><button class="btn-back">{{ $t("cancel") }}</button></router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "changePassword",
  data() {
    return {
      colorMsgPassword: "danger",
      oldPassword: "",
      password: "",
      password2: "",
      check: true,
      msg_error: "",
      loading: false,
      logoutFromAllDevices: false,
    };
  },
  computed: {
    ...mapGetters("userData", [
      "client",
      "sub_client",
      "responsible_branch",
      "government",
      "error_msg",
      "user",
      "success_api",
    ]),
    isFormValid() {
      if (
        this.oldPassword != "" &&
        this.password != "" &&
        this.password.length >= 8 &&
        this.password2 != "" &&
        this.password == this.password2
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("userData", ["changePassword"]),

    showHidePass(ref) {
      if (this.$refs[ref].type == "password") {
        this.$refs[ref].type = "text";
      } else {
        this.$refs[ref].type = "password";
      }
    },

    checkPassword() {
      if (this.password != this.password2) {
        this.check = false;
        this.$refs.password.style.border = "0.0625rem solid red";
        return false;
      } else {
        this.check = true;
        this.$refs.password.style.border = "0.0625rem solid #D8D6DE";
        return true;
      }
    },

    async submit() {
      if (!this.checkPassword()) {
        return;
      }

      const data = {
        oldPassword: this.oldPassword,
        password: this.password,
        logout_devices: this.logoutFromAllDevices || false,
      };

      try {
        this.loading = true;
        await this.changePassword(data);

        if (this.success_api == 0) {
          this.colorMsgPassword = "danger";
          this.msg_error = this.error_msg;
        } else {
          this.oldPassword = "";
          this.password = undefined;
          this.password2 = "";
          this.colorMsgPassword = "success";
          this.msg_error = "تم تغيير كلمة السر بنجاح";
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      } catch (err) {
        console.warn(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_setting.scss";
</style>
